<template>
  <div class="page-header">
    <button
      v-if="backButton"
      class="header-back__btn"
      @click="onBtnClickBack()"
    >
      <arrow-icon
        width="100%"
        height="100%"
        direction="left"
        stroke-size="normal"
      ></arrow-icon>
    </button>
    <h2 class="page-header__title sub-title-s1">
      {{ title }}
      <span v-if="subTitle" class="">{{ subTitle }}</span>
    </h2>
    <p v-if="description" class="page-header__desc text-gray-second">
      {{ description }}
    </p>

    <div class="header-right__section">
      <slot name="headerRightSide"></slot>
      <a
        v-if="guideLink"
        class="sub-text-s2 guide-link"
        :href="guideLink"
        target="_blank"
      >
        <div class="guide-icon">
          <info-icon
            fill-color="#0D0D10"
            width="100%"
            height="100%"
          ></info-icon>
        </div>
        <span>가이드&팁</span>
      </a>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import InfoIcon from "../icons/InfoIcon";
import { computed, reactive } from "vue";
import helper from "@/helper";
import ArrowIcon from "../icons/ArrowIcon";

export default {
  name: "PageHeader",
  components: {
    ArrowIcon,
    InfoIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: Object,
      required: false,
    },
    isBackBtnAction: {
      type: Boolean,
      default: false,
    },
    guideLink: {
      type: String,
      default: "",
      required: false,
    },
  },
  emits: ["backBtnAction"],
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive({
      guideIconSize: computed(() => {
        if (helper.isTabletSize()) {
          return 24;
        }
        return 16;
      }),
    });

    const onBtnClickBack = () => {
      if (props.isBackBtnAction) {
        emit("backBtnAction");
        return;
      }

      if (props.routeName) {
        router.push(props.routeName);
      } else {
        router.go(-1);
      }
    };

    return { state, onBtnClickBack };
  },
};
</script>

<style scoped>
.page-header {
  padding: 16px 20px;
  background-color: #ffffff;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-back__btn {
  width: 20px;
  height: 20px;
  border: 0;
  background: transparent;
  padding: 0;
}

.page-header__title {
  text-align: center;
  margin-bottom: 0;
  color: #0d0d10;
  flex-grow: 1;
}

.header-right__section {
  align-self: flex-end;
  display: flex;
  justify-self: center;
}

.header-right__section a {
  align-items: center;
  padding-left: 8px;
  color: #0d0d10;
}

.page-header__desc {
  display: none;
}

.guide-link {
  text-decoration: none;
}

.guide-link .guide-icon {
  width: 24px;
  height: 24px;
}

.guide-link span {
  display: none;
}

@media (min-width: 768px) {
  .page-header {
    padding: 16px 24px;
  }
}

@media (min-width: 1024px) {
  .page-header {
    background-color: transparent;
    text-align: left;
    padding: 0 0 40px 0;
    justify-content: unset;
  }

  .page-header__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    flex-grow: 0;
  }

  .header-back__btn {
    width: 24px;
    height: 24px;
    display: inline;
    vertical-align: middle;
    position: relative;
    margin-right: 8px;
  }

  .header-back__btn i {
    font-size: 18px;
  }

  .page-header__title {
    font-weight: 700;
    display: inline;
    vertical-align: middle;
    font-size: 24px;
    text-align: left;
    color: #0d0d10;
    flex-basis: auto;
  }

  .page-header__desc {
    display: block;
    align-self: flex-end;
    padding-left: 16px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    flex-grow: 1;
  }

  .header-right__section {
    flex-basis: auto;
    margin-left: auto;
  }

  .guide-link {
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }

  .guide-link .guide-icon {
    width: 16px;
    height: 16px;
  }

  .guide-link span {
    padding-left: 6px;
    display: inline;
    align-self: flex-end;
    align-items: flex-end;
    line-height: 14px;
  }
}
</style>
