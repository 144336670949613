<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header title="공지사항" :back-button="true"></page-header>
      <div class="notice-detail-wrapper">
        <div class="notice-card">
          <div class="notice-header">
            <span
              :class="state.notice.isMust ? 'text-blue-50' : 'text-gray-second'"
            >
              {{ state.notice.isMust ? "필독 공지" : "업데이트" }}
            </span>
            <span class="sub-text-s2 text-gray-second">
              {{ dateFormat(state.notice.createdAt) }}
            </span>
          </div>
          <h3>{{ state.notice.title }}</h3>
          <p
            class="text-gray-second b-text-1"
            v-html="state.notice.contentHtml"
          ></p>
        </div>

        <div class="btns-wrapper">
          <div class="page-move-btns">
            <button
              class="prev-btn bg-gray-010 sub-title-s1"
              @click="actions.goToPrevNotice()"
            >
              이전 글
            </button>
            <button
              class="next-btn bg-gray-010 sub-title-s1"
              @click="actions.goToNextNotice()"
            >
              다음 글
            </button>
          </div>
          <button
            class="back-to-list-btn sub-title-s1 bg-gray-010"
            @click="actions.goToList()"
          >
            목록으로 돌아가기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import { computed, onBeforeMount, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PageLoading from "../../../components/console/loadings/PageLoading";
import swal from "@/helper/swal";
import { dateFormat } from "@/helper/date";

export default {
  name: "ShowNotice",
  components: { PageLoading, PageHeader },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      to.params.noticeResourceId &&
      to.params.noticeResourceId !== from.params.noticeResourceId
    ) {
      next();
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      notices: computed(() => {
        return store.getters["notices/notices"];
      }),
      notice: null,
      pageLoading: true,
      isPrev: computed(() => {
        if (state.notice) {
          let noticeIndex = state.notices.findIndex((item) => {
            return item.resourceId === state.notice.resourceId;
          });
          return noticeIndex !== 0;
        }
        return false;
      }),
      isNext: computed(() => {
        if (state.notice) {
          let noticeIndex = state.notices.findIndex((item) => {
            return item.resourceId === state.notice.resourceId;
          });
          return noticeIndex < state.notices.length - 1;
        }
        return false;
      }),
    });

    watch(
      () => route.params.noticeResourceId,
      (newResourceId) => {
        let payload = {
          isMust: null,
          q: "",
        };

        store
          .dispatch("notices/getNotices", {
            data: payload,
          })
          .then(() => {
            state.notice = state.notices.find((item) => {
              return item.resourceId === newResourceId;
            });
            state.pageLoading = false;
          });
      }
    );

    onBeforeMount(() => {
      let payload = {
        isMust: null,
        q: "",
      };

      store
        .dispatch("notices/getNotices", {
          data: payload,
        })
        .then(() => {
          state.notice = state.notices.find((item) => {
            return item.resourceId === route.params.noticeResourceId;
          });
          state.pageLoading = false;
        });
    });

    const actions = {
      goToList: () => {
        router.push({ name: "console.notices" });
      },
      goToPrevNotice: () => {
        if (state.isPrev) {
          let prevIndex =
            state.notices.findIndex((item) => {
              return item.resourceId === state.notice.resourceId;
            }) - 1;

          let prevNotice = state.notices[prevIndex];

          router.push({
            name: "console.notices.show",
            params: {
              noticeResourceId: prevNotice.resourceId,
            },
          });
        } else {
          swal.messageAlert("이전 글이 없습니다.");
        }
      },
      goToNextNotice: () => {
        if (state.isNext) {
          let nextIndex =
            state.notices.findIndex((item) => {
              return item.resourceId === state.notice.resourceId;
            }) + 1;

          let nextNotice = state.notices[nextIndex];

          router.push({
            name: "console.notices.show",
            params: {
              noticeResourceId: nextNotice.resourceId,
            },
          });
        } else {
          swal.messageAlert("다음 글이 없습니다.");
        }
      },
    };

    return { state, actions, dateFormat };
  },
};
</script>

<style src="./style.css" scoped></style>
